import * as React from "react";
import { QuizTypes, useQuiz } from "./useQuiz";
import { Quizlet1 } from "./types/quizlet1/quizlet1";
import { useBreakpoint } from "app/utils/useBreakpoint";
import { Quiz2 } from "./types/quiz2/quiz2";

export interface QuizProps {}

export const Quiz: React.FC<QuizProps> = (props) => {
  const { type } = useQuiz();

  const getType = () => {
    switch (type) {
      case QuizTypes.QUIZLET_1:
        return <Quizlet1 />;
      case QuizTypes.QUIZ2:
        return <Quiz2 />;
      default:
        return <Quiz2 />;
    }
  };

  const quizType = getType();

  const { isAboveMd } = useBreakpoint("md");

  return (
    <div className="flex  p-1 pt-10 min-h-[100dvh] w-full justify-center bg-black">
      {quizType}
    </div>
  );
};
