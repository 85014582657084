import http from "app/axios/axiosInstance";
import {
  monthlyPlanDeals,
  MonthylPlanDealsEnum,
  trialPlanDeals,
} from "app/dictionaries/webFunnelPlanDictionary";
import { Path } from "app/path";
import { useQuery } from "app/utils/useQuery";
import { find } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { generatePath, useNavigate } from "react-router";
import { getParamsAsJson } from "app/quiz/types/quiz2/utils/getParamsJson";
import { usePostHog } from "posthog-js/react";

export const usePaymentUpsell = () => {
  const query = useQuery();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const email = query.get("email");
  const encodedEmail = email
    ? encodeURIComponent(email).replace(/%20/g, "%2B")
    : "";
  const decodedEmail = decodeURIComponent(encodedEmail);
  const plan = query.get("plan") || "1";
  const isReturningUser = query.get("returningUser") === "true";
  const fromCanceled = query.get("fromCanceled") === "true";
  const trialPlan = find(trialPlanDeals, {
    value: Number(parseFloat(plan!)),
  });
  const isSuccessfulRedirect = query.get("redirect_status") === "succeeded";
  const stepParam = Number(query.get("discountStep"));
  const [step, setStep] = useState(stepParam ? stepParam : 0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [clientSecret, setClientSecret] = useState();
  const [showPaymentStatusModal, setShowPaymentStatusModal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<"success" | "fail">();
  const [loadingPaymentStatus, setLoadingPaymentStatus] = useState(false);

  const createSetupIntent = async () => {
    (async () => {
      try {
        const { data } = await http.post(
          "/v1/web_quizzes/create_setup_intent",
          { email: decodedEmail }
        );
        setClientSecret(data.clientSecret);
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const onClaimDiscount = async () => {
    setStep(1);
    query.set("discountStep", "1");
    navigate(window.location.pathname + "?" + query.toString());
  };

  useEffect(() => {
    const setupIntentId = query.get("setup_intent");
    const clientSecretFromParam = query.get("setup_intent_client_secret");
    if (setupIntentId && clientSecretFromParam && email && plan) {
      query.set("priceId", trialPlan!.priceId as any);
      query.set("title", trialPlan!.title as any);
      query.set("value", trialPlan!.value as any);

      const successPath = `${generatePath(Path.QUIZ_PAYMENT_SUCCESS, {
        type: "quiz2",
      })}?${query.toString()}`;

      setLoadingPaymentStatus(true);
      const sub1999 = monthlyPlanDeals[MonthylPlanDealsEnum.SUB1999];
      const createSubscription = async () => {
        try {
          await http.post("/v1/web_quizzes/create_subscription", {
            ...getParamsAsJson(query), // Send all parameters to the backend
            setupIntentId,
            email: decodedEmail,
            quizNumber: 2,
            trialPriceId: trialPlan?.priceId,
            subPriceId: sub1999?.priceId,
            fromCanceled,
            toltReferral: window.tolt_referral,
          });
          navigate(successPath);
          setLoadingPaymentStatus(false);
        } catch (err: any) {
          console.log(err);
          console.log(err.data.code);
          if (
            err.data.error.code === "already_purchased" ||
            err.data.code === "already_purchased"
          ) {
            setShowPaymentStatusModal(true);
            setPaymentStatus("success");
            setLoadingPaymentStatus(false);
            return;
          }
          setShowPaymentStatusModal(true);
          setPaymentStatus("fail");
          setLoadingPaymentStatus(false);
        }
      };
      createSubscription();
    }
  }, []);

  useEffect(() => {
    if (!isSuccessfulRedirect && email) {
      // Fetch the client secret from the backend to render PaymentElement
      createSetupIntent();
      let postHogEvent;
      if (fromCanceled) {
        postHogEvent = "quiz_2_payment_upsell_viewed_from_canceled";
      } else if (isReturningUser) {
        postHogEvent = "quiz_2_returning_user_payment_upsell_viewed";
      } else {
        postHogEvent = "quiz_2_payment_upsell_viewed";
      }

      try {
        if (process.env.NODE_ENV === "production") {
          posthog?.capture(postHogEvent, {
            email,
            trial_value: trialPlan?.value,
          });
        }
      } catch (err) {
        console.error("Error capturing event with PostHog:", err);
      }
    }
  }, []);

  return {
    email,
    step,
    trialPlan,
    onClaimDiscount,
    clientSecret,
    showPaymentModal,
    setShowPaymentModal,
    showPaymentStatusModal,
    setShowPaymentStatusModal,
    paymentStatus,
    createSetupIntent,
    loadingPaymentStatus,
    fromCanceled,
  };
};
